import {setUserId, setUserName} from "@/libs/auth.js";
const state = {
    hasAuth: false,
    token: '',
    loginName: '',
    schoolId: '',
    userId: '',
    userImg: '',
    userName: '',
    isGoWXdata: false, // 是调用维修通知的接口（通知弹窗）
    isTZStatus: false, // 通知状态  false 不是从通知弹窗过来 true 是从通知弹窗过来
}

const mutations = {
    setAuth (state, auth) {
        state.hasAuth = auth;
    },
    setUserBasicInfo (state, infoData) {
        state.token = infoData.token;
        state.loginName = infoData.loginName;
        state.schoolId = infoData.schoolId;
        state.userId = infoData.userId;
        state.userImg = infoData.userImg;
        state.userName = infoData.userName;

        setUserId(state.userId);
        setUserName(state.userName);
    }
}


export default {
    state,
    mutations
}
