import { getUserDealtCount, getUserInfoData, getNewsNum } from "@/services";
import { removeClassSty } from "@/libs/utils";
import { removeToken } from "@/libs/auth.js";
import processUserInfo from '@/libs/user';
import { getUnReadNum } from '@/xkUI/pages/Feedback/src/https/index.js';
import { getUserThemeConfig }  from '@/libs/themeConfing.js';
import { initTheme } from '@/config/theme';
const AGENCY_API = process.env.VUE_APP_XKSERVER_FEED_AGENCY_API;

export default {
    async getUserInfo({commit, state, dispatch}) {
        let userInfo = await getUserInfoData();
        sessionStorage.setItem('teacherProduct',userInfo.data.projectInfo.teacherProduct);
        let theme = await getUserThemeConfig(userInfo);
        if (userInfo.code === "5015" || userInfo.code === "20011") {
            removeToken();
            return false;
        };
        
        const [ userBasicInfo, userPrivilegeInfo ] =  processUserInfo(userInfo.data);

        initTheme(theme);
        commit('updateStyle', theme);
        commit('setUserBasicInfo', userBasicInfo)
        commit('setUserPrivilegeInfo', userPrivilegeInfo)

        commit("setUserInfo", userInfo.data);
        commit("setUserRouters", userPrivilegeInfo.formatRouter);
        commit("setOriginRouters", userPrivilegeInfo.originRouter)
        commit("setAuth", true);
        dispatch("getNewsNum")
        getUserDealtCount({
            pageNum: 1,
            pageRow: 1,
            queryState: "1",
            schoolId: userInfo.data.schoolId,
        }).then((res) => {
            if (res.code === "200") {
                const count = res.data ? res.data.totalCount : 0;
                console.log('待办事项', count)
                commit("setDealtNum", count);
            }
        });
        setTimeout(() => {
            removeClassSty();
        });
    },

    removeUserInfo({commit, state}) {
        commit("removeUserInfo", false);
    },

    // 写入模版
    setTemplateStyle(ctx, data) {
        ctx.commit("setTemplateStyle", data);
    },

    addView({commit}, view) {
        commit("addView", view);
    },

    delView({commit, dispatch, state}, view) {
        return new Promise((resolve) => {
            commit("delView", view);
            resolve({
                visitedViews: [...state.visitedViews],
            });
        });
    },

    updateView({commit}, view) {
        commit("updateView", view);
    },

    getNewsNum({commit, dispatch, state}) {

        let configList = [//消息通知配置
            {
                key:"all",
                id:["15","18","21"],
                permi:"message:all",
                ishave:false
            },
            {
                key:"official",
                id:["15"],
                permi:"message:official",
                ishave:false
            },
            {
                key:"access",
                id:["18"],
                permi:"message:access",
                ishave:false
            },
            {
                key:"access",
                id:["21"],
                permi:"message:courseRecord",
                ishave:false
            }
        ];

        // 检验是否有权限
        configList.forEach(ele => {
            if(state.permissions.includes(ele.permi)){
                ele.ishave = true; // 设置标志位，表示当前用户拥有该权限
            }
        });

        let ishaveList = configList.filter(ele => ele.ishave); // 获取用户拥有的权限

        if(ishaveList.length == 0){//如果无权限则return
            return
        }

        let typeList = [];
        ishaveList.forEach(ele => {
            typeList = typeList.concat(ele.id);
        });

        // typeList去重
        typeList = Array.from(new Set(typeList));
        console.log("typeList",typeList);


        getNewsNum({
            newsStatus: "0",
            receiverId: state.userId,
            newsTypeList: typeList
        }).then((res) => {
            commit("setNewsNum", res.data.num || 0)
        });
    },
    async getHasNewFeedback({commit, state, dispatch}) {
        if (!state.userId) return;
        const res =  await getUnReadNum({'userId': state.userId},AGENCY_API);
        if (res.code === '200') {
            commit("setFeedbackNum", Number(res.data) || 0);
        };
    }
};
